import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hooks and hunting - Youth learn outdoor skills with new club`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/393490c2cc65c6bb7aefe078e9eae296/63a7e/hunters-anglers-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdpAW0VR/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACEhMD/9oACAEBAAEFAuGZzrAqD7aq2YT/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEhH/2gAIAQMBAT8Btev/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAgEBPwGEP//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhIhEHH/2gAIAQEABj8CVma2aRlXSXvP/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8hphN8W4Dd+i7PYTKL2YnH1HHpk432f//aAAwDAQACAAMAAAAQqO//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxDmJ6Tqs//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESFR/9oACAECAQE/EFcsTSF//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFRQZH/2gAIAQEAAT8QUMAppGnamipUQpeVV/ZgGt2rbF9ALyqK2g55M1EKloMvdT//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "hunters anglers 1",
            "title": "hunters anglers 1",
            "src": "/static/393490c2cc65c6bb7aefe078e9eae296/63a7e/hunters-anglers-1.jpg",
            "srcSet": ["/static/393490c2cc65c6bb7aefe078e9eae296/e07e9/hunters-anglers-1.jpg 200w", "/static/393490c2cc65c6bb7aefe078e9eae296/066f9/hunters-anglers-1.jpg 400w", "/static/393490c2cc65c6bb7aefe078e9eae296/63a7e/hunters-anglers-1.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`It’s important for children to have the opportunity to learn fishing etiquette and hunting skills, which they enjoyed through the Arizona Junior Hunters and Anglers in Williams.
The club taught outdoor skills that will include firearm safety, marksmanship, first aid, camping and outdoor cooking, among others.
“Everything that’s going to help them grow into better hunters and sportsmen,” said Justin Smith, president of the Arizona Junior Hunters and Anglers in Williams.
Smith said the idea for the club was sparked after he and his wife attended banquets for the NRA and Arizona Elk Society and realized the lack of hunting/fishing clubs geared toward children. They decided they wanted to change that.`}</p>
    <p>{`“We noticed that there wasn’t a lot in those clubs for kids… we wanted something that the kids could do all year long,” he said.`}</p>
    <p>{`Smith said the interest expressed in Williams and surrounding communities for this type of club has been tremendous. At a kick-off for the club last month, organizers went to Juniper Creek Outdoors in Williams and signed up 21 children within five hours.`}</p>
    <p>{`“Since then, we now have 46 kids in our club,” he said.`}</p>
    <p>{`About 95 percent of club members are from Williams, other members are from Prescott, Chino Valley, Ash Fork and Flagstaff.`}</p>
    <p>{`“I grew up hunting (and) fishing my whole life with my parents and friends and I wanted the kids to be able to do that too. We’re not saying no to anybody. Our age groups are 5-18 years old,” Smith said.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/446dc6fb729a33e67ac47edfd8c3f9f2/c08c5/hunters-anglers-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABHPTO8nC//8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAERID/9oACAEBAAEFAlpY1dAMTmx0rnNT/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAEDAQE/ATFnb//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AZQ//8QAHBAAAgAHAAAAAAAAAAAAAAAAABEBEBIhMVGh/9oACAEBAAY/AqXEejHRu4nL/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBUf/aAAgBAQABPyGrS5b3ZV6T0PYN47I9SEpZ8qY2Vt2f/9oADAMBAAIAAwAAABCrP//EABgRAAIDAAAAAAAAAAAAAAAAAAABITFR/9oACAEDAQE/EFWyPQ//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Qph//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRYYHB/9oACAEBAAE/EFuplrLoNdS1lkCtls4gstjtXuBURUWma+zFUXX5ywnAVbU//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "hunters anglers 2",
            "title": "hunters anglers 2",
            "src": "/static/446dc6fb729a33e67ac47edfd8c3f9f2/c08c5/hunters-anglers-2.jpg",
            "srcSet": ["/static/446dc6fb729a33e67ac47edfd8c3f9f2/e07e9/hunters-anglers-2.jpg 200w", "/static/446dc6fb729a33e67ac47edfd8c3f9f2/066f9/hunters-anglers-2.jpg 400w", "/static/446dc6fb729a33e67ac47edfd8c3f9f2/c08c5/hunters-anglers-2.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In 2019, junior club members voted for president, vice president and treasurer during their first club meeting.`}</p>
    <p>{`“Because it’s their club, we’re going to show them responsibility, but let them run the club,” Smith said. “We’re just the overseers and advisors of the club.”`}</p>
    <p>{`Oversight and adult supervision has not been overlooked. According to Smith, range safety officers with the Williams Sportsman’s Club will help club members as well as trained professionals including local game processors, hunting guides and branding inspectors.`}</p>
    <p>{`“I’m also getting my NRA certification (as a) range safety officer, I’ve got 12 years of law enforcement and military background and over 40 years of hunting and fishing (experience),” Smith said. “I have a guide that is willing to take our children out for hunts … we have a lot of help within the community.`}</p>
    <p>{`Smith said the club is insured and will offer a hunters safety course that children can go through with their parents.`}</p>
    <p>{`“Parents are also entitled to sit in and if they don’t have their hunter safety card they can obtain their card at that time also,” Smith said. “The kids can’t do it without the parent’s support so we’re going to let the parents in there also.”`}</p>
    <p>{`The club plans to have four events per month. They meet the last Sunday of every month at either the Williams Rodeo Barn or at the Rec Center in Williams. More information about Williams Hunters and Anglers Club is available from Justin Smith (928) 846-9047 or `}<a parentName="p" {...{
        "href": "mailto:azjhaac@gmail.com"
      }}>{`azjhaac@gmail.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      